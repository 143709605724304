import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "50",
  height: "50",
  viewBox: "0 0 50 50",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M7 2.5V47.5H43V13.6776L32.1841 2.5H7Z",
      fill: "#212529"
    }, null, -1),
    _createElementVNode("path", {
      d: "M38.5854 14.9415L29.6914 6.10742V14.9415H38.5854Z",
      fill: "white"
    }, null, -1),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M35.1794 26.2767H15.0586V23.6963H35.1794V26.2767Z",
      fill: "#F06F02"
    }, null, -1),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M35.1794 31.5697H15.0586V28.9893H35.1794V31.5697Z",
      fill: "#F06F02"
    }, null, -1)
  ])))
}
export default { render: render }